<template>
  <div>
    <validation-observer
      v-show="!success"
      ref="forgotPasswordForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="forgotPasswordRequest"
      >
        <b-form-group
          label="Email"
          label-for="forgot-password-email"
        >
          <validation-provider
            #default="{ errors }"
            vid="email"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="forgot-password-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false:null"
              name="forgot-password-email"
              placeholder="Email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- server alert -->
        <b-alert
          v-height-fade.appear
          variant="danger"
          :show="serverError !== null"
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            <span v-html="serverError" />
          </div>
        </b-alert>

        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="invalid"
        >
          {{ $t(`forgot_password.button_text`) }}
        </b-button>
      </b-form>
    </validation-observer>

    <div
      v-show="success"
      id="reset-form-success"
      class="mb-4"
    >
      <sweet-alert-static display-type="success" />
      <p class="mb-4">
        {{ successMsg }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  BAlert, BForm, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import { required, email } from '@validations'
import { mapActions } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    SweetAlertStatic: () => import('@/views/components/sweet-alert-static/SweetAlertStatic.vue'),
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      success: false,
      successMsg: null,
      serverError: null,
    }
  },
  methods: {
    ...mapActions('auth', ['recoverPassword']),
    forgotPasswordRequest() {
      this.$refs.forgotPasswordForm.validate().then(async result => {
        if (result) {
          this.serverError = null
          // // this.$toast({
          // //   component: ToastificationContent,
          // //   props: {
          // //     title: 'This is for UI purpose only.',
          // //     icon: 'EditIcon',
          // //     variant: 'success',
          // //   },
          // // })
          try {
            const response = await this.recoverPassword({ email: this.userEmail.toLowerCase() })
            this.success = true
            this.successMsg = response.data.message
            this.$emit('success')
          } catch (err) {
            if (err.response && err.response.status < 500) {
              // add error to vee-validate error bag
              this.$refs.forgotPasswordForm.setErrors({
                email: [err.response.data.message],
              })
            } else {
              this.serverError = this.$t('errors.generic')
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
#forgot-password-email {
  text-transform: lowercase;
}
</style>
