<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <dentyme-logo />

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password"
            class="forgot-password-img"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t(`forgot_password.title`) }} 🔒
          </b-card-title>
          <b-card-text
            v-show="!success"
            class="mb-2"
          >
            {{ $t(`forgot_password.instructions`) }}
          </b-card-text>

          <!-- form -->
          <forgot-password-form @success="hideOnSuccess" />
          <!-- /form -->

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t(`forgot_password.back_to_login`) }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import DentymeLogo from '@/views/components/dentyme-logo/DentymeLogo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg,
} from 'bootstrap-vue'
import store from '@/store/index'
import ForgotPasswordForm from '@/views/components/forms/ForgotPasswordForm.vue'

export default {
  components: {
    DentymeLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BCardTitle,
    BCardText,
    ForgotPasswordForm,
  },
  data() {
    return {
      sideImg: require('@/assets/images/svg/forgot-password.svg'),
      success: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    hideOnSuccess() {
      this.success = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.forgot-password-img {
  max-width: 60%;
  max-height: 60%;
}
</style>
