<template>
  <b-link class="brand-logo brand-logo-alt">
    <b-img
      src="@/assets/images/logo/dentyme-logo.png"
      fluid
      alt="Responsive image"
    />
    <h2
      v-if="showText"
      class="brand-text text-white ml-1"
    >
      DenTyme
    </h2>
  </b-link>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BLink,
  },
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
  .brand-logo-alt {
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 64px;
      height: 64px;
    }
  }
</style>
